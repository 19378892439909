import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module LocationsSaga
 */

/**
 *
 * @param {object} payload currentLocation has latitude and longitude
 */
export function setUserLocation(currentLocation) {
  store.dispatch({
    type: actionTypes.SET_USER_LOCATION,
    payload: {
      currentLocation: currentLocation,
    },
  });
}

export function addUserDistance() {
  store.dispatch({
    type: actionTypes.ADD_USER_DISTANCE,
  });
}

export function getLocations(locationIds) {
  store.dispatch({
    type: actionTypes.GET_LOCATIONS,
    payload: {
      locationIds: locationIds,
    },
  });
}

/**
 *
 * @param {object} data
 */
export function setSelectedLocation(data) {
  store.dispatch({
    type: actionTypes.ADD_SELECTED_LOCATION,
    payload: {
      data: data,
    },
  });
}
