import { api } from "../Utils/constants";
import { setErrorStatus } from "../Redux/status/action";

export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${
      api.baseUrl
    }/profile/isExists?type=employee&phoneNumber=${encodeURIComponent(
      phoneNumber
    )}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}

export async function compareFace(locationData, accessToken) {
  const response = await fetch(`${api.baseUrl}/faceComparison`, {
    // const response = await fetch(
    //   `http://localhost:5000/faceComparison`,{
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-auth-token": `Bearer ${accessToken}`,
    },
    body: locationData,
  });
  const result = await response.json();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}
