const complaintsReducer = (
  state = {
    loading: false,
    data: null,
    totalCount: null,
    filter: {
      category: [],
      dateRange: {
        from: null,
        to: null,
      },
      issueRaised: "",
      PCTNo: [],
    },
    searchKey: "",
    sortBy: "desc",
  },
  action
) => {
  switch (action.type) {
    case "SET_COMPLAINTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_COMPLAINTS":
      return {
        ...state,
        data: action.payload.data,
      };

    case "SET_COMPLAINTS_COUNT":
      return {
        ...state,
        totalCount: action.payload.data,
      };

    case "UPDATE_COMPLAINTS_WITH_ASSETS":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.complaintId]: action.payload.data,
        },
      };

    case "CLEAR_COMPLAINTS":
      return {
        ...state,
        data: null,
      };

    case "PUT_FILTER":
      return {
        ...state,
        filter: action.payload.data,
      };

    case "PUT_SEARCH_KEY":
      return {
        ...state,
        searchKey: action.payload.data,
      };

    case "PUT_SORT_BY":
      return {
        ...state,
        sortBy: action.payload.data,
      };

    case "RESET":
      return state;

    default:
      return state;
  }
};
export default complaintsReducer;
