import {
  arrayUnion,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  getCountFromServer,
  where,
  writeBatch,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { setAccountProfiles } from "../Redux/profile/action";
import { setErrorStatus } from "../Redux/status/action";
import { database } from "./firebase";
import { uploadProcedureHistoryFiles } from "./storage";
import { isValidObject } from "./validators";

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null,
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "employees"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let profiles = {};
          dataSnapshot.forEach((doc) => {
            profiles = {
              ...doc.data(),
            };
          });
          setAccountProfiles(profiles);
        },
        (error) => {
          console.error(error, "from profile");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (props.isAuth && profileListener.listener === null) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber),
      });
    } else if (
      !props.isAuth &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber]);
}

export async function getProceduresByProcedureIds(procedureIds) {
  let proceduresData = {};
  for (let i = 0; i < procedureIds.length; i++) {
    const rootRef = doc(database, "procedures", procedureIds[i]);
    const data = (await getDoc(rootRef)).data();
    proceduresData = {
      ...proceduresData,
      [procedureIds[i]]: { ...data, documentId: procedureIds[i] },
    };
  }
  return proceduresData;
}

export async function getComplaint(complaintId) {
  const docRef = doc(database, "complaints", complaintId);
  const docSnapshot = await getDoc(docRef);
  const complaintData = docSnapshot.data();
  if (
    isValidObject(complaintData) &&
    complaintData?.status?.currentStatus === "OPEN"
  ) {
    return { ...complaintData, documentId: docSnapshot.id };
  } else {
    return null;
  }
}

export async function getComplaintsTotalCount(props) {
  let complaintsRef = query(
    collection(database, "complaints"),
    where("connectedPhoneNumbers", "array-contains", props.phoneNumber),
    where("status.currentStatus", "==", "OPEN")
  );

  if (props.filters.length > 0) {
    props.filters?.forEach((value, index) => {
      if ((index + 1) % 3 === 0 && index !== 0) {
        complaintsRef = query(
          complaintsRef,
          where(props.filters[index - 2], props.filters[index - 1], value)
        );
      }
    });
  }

  const count = await getCountFromServer(complaintsRef);
  return count.data().count;
}

export async function getProceduresHistory(
  procedureId,
  locationId,
  employeeId
) {
  const searchData = query(
    collectionGroup(database, "procedureHistory"),
    where("locationId", "==", locationId),
    where("procedureId", "==", procedureId),
    where("employeeId", "==", employeeId)
  );
  const querySnapshot = await getDocs(searchData);
  const obj = {};
  querySnapshot.docs.forEach((doc) => {
    obj[locationId] = {
      ...obj[locationId],
      [doc.id]: {
        ...doc.data(),
        documentId: doc.id,
      },
    };
  });
  return obj;
}

export async function getLocationsByLocationIds(locationIds) {
  let locationsData = {};
  for (let i = 0; i < locationIds.length; i++) {
    const rootRef = doc(database, "locations", locationIds[i]);
    const data = (await getDoc(rootRef)).data();
    locationsData = {
      ...locationsData,
      [locationIds[i]]: { ...data, documentId: locationIds[i] },
    };
  }
  return locationsData;
}

export async function getInternalLocations() {
  let locationsData = {};
  const queryRef = query(
    collection(database, "locations"),
    where("access", "==", "internal")
  );
  const querySnapshot = await getDocs(queryRef);
  querySnapshot.docs.forEach((doc) => {
    locationsData = {
      ...locationsData,
      [doc.id]: {
        ...doc.data(),
        documentId: doc.id,
      },
    };
  });
  return locationsData;
}

export async function getAllLocations() {
  let data = {};
  const locationsData = query(collection(database, "locations"));
  const querySnapshot = await getDocs(locationsData);
  querySnapshot.docs.forEach((doc) => {
    data = {
      ...data,
      [doc.id]: {
        ...doc.data(),
        documentId: doc.id,
      },
    };
  });
  return data;
}

export async function setProcedureHistory(data, files) {
  const batch = writeBatch(database);
  const rootRef = doc(
    collection(database, "locations", data.locationId, "procedureHistory")
  );

  let latestUpdateData = [];
  for (let i = 0; i < files?.length; i++) {
    let fileSrc = [];
    for (let j = 0; j < files[i].proof?.length; j++) {
      const fileName = files[i]?.proof[j]?.name
        ? files[i]?.proof[j]?.name
        : files[i]?.proof[j]?.size;
      if (typeof fileName === "string") {
        const filePath = await uploadProcedureHistoryFiles(
          `/${data.locationId}/procedures/${data.procedureId}/${rootRef.id}/${
            i + 1
          }/${fileName}`,
          files[i].proof[j]
        );
        fileSrc.push(filePath);
      }
    }
    latestUpdateData.push({
      proof: fileSrc,
      success: files[i].success,
      ...(files[i].remarks ? { remarks: files[i].remarks } : {}),
      ...(files[i].selectedKpi ? { kpi: files[i].selectedKpi } : {}),
      ...(files[i].selectedPenalty
        ? { penalty: files[i].selectedPenalty }
        : {}),
      ...(files[i].selectedRemarks
        ? {
            requiredRemarks: files[i].selectedRemarks,
          }
        : {}),
      title: files[i].title,
      description: files[i].description,
    });
  }

  const docRef = doc(
    database,
    "locations",
    data.locationId,
    "procedureHistory",
    rootRef.id
  );

  batch.set(docRef, { ...data, latestUpdate: latestUpdateData });
  await batch.commit();
}

export async function compliantClosure(compliantId, employeeId, proof) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    status: { currentStatus: "UNDER REVIEW", updatedAt: +new Date() },
    closure: {
      resolvedAt: +new Date(),
      resolvedBy: employeeId,
      proof: proof,
    },
  });
}

export async function updateAccessSyncStatus(employeeId, status) {
  const rootRef = doc(database, "employees", employeeId);

  await updateDoc(rootRef, {
    accessSyncStatus: status,
  });
}

export async function addUpdatesToCompliant(remarks, compliantId, proof) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    updates: arrayUnion({
      proof: proof,
      remarks: remarks,
      updatedAt: +new Date(),
    }),
  });
}

export async function takeoverCompliant(compliantId, employeeData) {
  const rootRef = doc(database, "complaints", compliantId);

  await updateDoc(rootRef, {
    takenOverBy: employeeData,
  });
}
