import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module ComplaintsSaga
 */

/**
 *
 * @param {complaints} payload complains data
 */
export function setComplaints(complaints, filters) {
  store.dispatch({
    type: actionTypes.SET_COMPLAINTS_DATA,
    payload: {
      complaints: complaints,
      filters: filters,
    },
  });
}

/**
 *
 * @param {data} payload complains data
 */
export function updateComplaints(remarks, complaintId, locationId, proof) {
  store.dispatch({
    type: actionTypes.UPDATE_COMPLAINTS_DATA,
    payload: {
      remarks: remarks,
      complaintId: complaintId,
      locationId: locationId,
      proof: proof,
    },
  });
}

/**
 *
 * @param {type} payload complains data
 */
export function fetchCompliantAssets(complaintId) {
  store.dispatch({
    type: actionTypes.FETCH_COMPLAINTS_ASSETS,
    payload: {
      complaintId: complaintId,
    },
  });
}
/**
 *
 * @param {type} payload complains data
 */
export function fetchUpdateCompliantAssets(complaintId) {
  store.dispatch({
    type: actionTypes.FETCH_UPDATE_COMPLAINTS_ASSETS,
    payload: {
      complaintId: complaintId,
    },
  });
}

/**
 *
 * @param {data} payload complains data
 */
export function fixComplaints(
  complaintId,
  locationId,
  employeeId,
  proof,
  navigate
) {
  store.dispatch({
    type: actionTypes.FIX_COMPLAINTS,
    payload: {
      complaintId: complaintId,
      employeeId: employeeId,
      locationId: locationId,
      proof: proof,
      navigate: navigate,
    },
  });
}

export function complaintTakeover(complaintId, employeeData) {
  store.dispatch({
    type: actionTypes.COMPLAINT_TAKEOVER,
    payload: {
      complaintId: complaintId,
      employeeData: employeeData,
    },
  });
}

export function setFilter(data) {
  store.dispatch({
    type: actionTypes.SET_FILTER,
    payload: {
      data: data,
    },
  });
}

export function setSearchKey(data) {
  store.dispatch({
    type: actionTypes.SET_SEARCH_KEY,
    payload: {
      data: data,
    },
  });
}

export function setSortBy(data) {
  store.dispatch({
    type: actionTypes.SET_SORT_BY,
    payload: {
      data: data,
    },
  });
}
